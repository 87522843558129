import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    :root {
        --primary-light: #FBCC7E;
        --primary: #FAB74A;
        --primary-dark: #F9A41B;
        --secondary-light: #FFFCF5;
        --secondary: #FFF6E3;
        --secondary-dark: #FFE6B3;
        --tertiary-light: #BED7E4;
        --tertiary: #9AC0D5;
        --tertiary-dark: #76AAC6;

        --success-light: #8AE59C;
        --success: #60DC79;
        --success-dark: #36D356;
        --warning-light: #E5D78A;
        --warning: #DCC860;
        --warning-dark: #D3BA36;
        --error-light: #E58A8A;
        --error: #DC6060;
        --error-dark: #D33636;
        --info-light: #9E9ED1;
        --info: #7C7CC1;
        --info-dark: #5959B0;

        --grubhub-light: #F3495B;
        --grubhub: #F01A31;
        --grubhub-dark: #C90D21;
        --ubereats-light: #64BF5E;
        --ubereats: #49A943;
        --ubereats-dark: #398334;
        --doordash-light: #FF2E34;
        --doordash: #FB0007;
        --doordash-dark: #C70006;
        --postmates-light: #474747;
        --postmates: #333333;
        --postmates-dark: #1A1A1A;

        --light: #FCFCFC;
        --gray: #E0E0E0;
        --gray-2: #C2C2C2;
        --gray-3: #A3A3A3;
        --gray-4: #8F8F8F;
        --gray-5: #7A7A7A;
        --gray-6: #666666;
        --gray-7: #474747;
        --gray-8: #333333;
        --dark: #1A1A1A;

        --gap: 1rem;
        --border-radius: 0.5rem;
        --font-size: 1rem;
        --font-weight-light: 100;
        --font-weight-regular: 400;
        --font-weight-bold: 800;

        --length-xs: 1rem;
        --length-sm: 1.25rem;
        --length-md: 1.5rem;
        --length-lg: 1.75rem;
        --length-xl: 2rem;
        
        --screen-xs: 0;
        --screen-sm: 36rem;
        --screen-md: 48rem;
        --screen-lg: 62rem;
        --screen-xl: 120rem;

        --max-width-xs: 100%;
        --max-width-sm: 100%;
        --max-width-md: 90%;
        --max-width-lg: 56.25rem;
        --max-width-xl: 67.5rem;
    }

    *, *::before, *::after {
        box-sizing: border-box;
    }

    * {
        transition: all 0.25s linear;
    }

    body {
        margin: 0;
        font-family: 'Montserrat', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
        font-size: var(--font-size);
        font-weight: var(--font-weight-regular);
        line-height: 140%;
        --webkit-text-adjust: 100%;
        background: var(--secondary);
        color: var(--dark);
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Staatliches', cursive;
        line-height: 1.25;
    }

    h1, h2, h3 {
        margin: calc(var(--gap) * 0.5) 0;
    }

    h4, h5, h6 {
        margin: calc(var(--gap) * 0.15) 0;
    }

    h1 {
        font-size: calc(1.275rem + 5vw);
    }
    @media (min-width: 48rem) {
        h1 {
            font-size: calc(var(--font-size) * 3.5);
        }
    }

    h2 {
        font-size: calc(1.225rem + 3.5vw);
    }
    @media (min-width: 48rem) {
        h2 {
            font-size: calc(var(--font-size) * 2.5);
        }
    }

    h3 {
        font-size: calc(1.2rem + 3vw);
    }
    @media (min-width: 48rem) {
        h3 {
            font-size: calc(var(--font-size) * 2);
        }
    }

    h4 {
        font-size: calc(1.175rem + 2.5vw);
    }
    @media (min-width: 48rem) {
        h4 {
            font-size: calc(var(--font-size) * 1.75);
        }
    }

    h5 {
        font-size: calc(var(--font-size) * 1.5);
    }

    h5 {
        font-size: calc(var(--font-size) * 1.5);
    }

    h6 {
        font-size: calc(var(--font-size) * 1.25);
    }

    a {
        display: inline-block;
    }

    p {
        margin-top: 0;
        margin-bottom: var(--gap);
    }

    a:active, a:focus, button:active, button:focus {
        outline: none;
    }

    img {
        vertical-align: middle;
        border-style: none;
        width: 100%;
    }

    svg {
        fill: var(--dark);
    }

    .fadeIn {
        opacity: 0;
    }
`;