import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import { Dropdown, DropdownLink } from '../shared/Dropdown';
import { TextLink } from '../shared/TextLink';
import { Topbar, TopbarNav, TopbarNavItem, TopbarLogo, TopbarMobileNav } from '../shared/Topbar';

import Logo from '../../assets/icons/the-egg-and-us-logo.png';
import { ReactComponent as EggIcon } from '../../assets/icons/egg-icon-face.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/menu-icon.svg';
import { ReactComponent as CloseIcon } from '../../assets/icons/close-icon.svg';

export default function Navbar() {
    const [nav, setNav] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const variants = {
        open: { opacity: 1, y: 0 },
        closed: { opacity: 0, y: -15, display: "none" }
    }

    const handleNav = () => {
        setNav(!nav);
        
        if (dropdown) {
            setDropdown(false);
        }
    }

    const handleDropdown = () => setDropdown(!dropdown);

    return (
        <Topbar role="navigation">
            <a href="https://theeggandus.com">
                <TopbarLogo width="56px" height="49px">
                    <img src={Logo} alt="The Egg & Us Logo" />
                </TopbarLogo>
            </a>
            <TopbarNav nav={nav}>
                <TopbarNavItem onClick={handleNav}>
                    <TextLink href="https://theeggandus.com">
                        <EggIcon alt="Egg Icon" /> Home
                    </TextLink>
                </TopbarNavItem>
                <DropdownLink onClick={handleDropdown}>
                    <TopbarNavItem>
                        <TextLink>
                            <EggIcon alt="Egg Icon" /> Locations &#9662;
                        </TextLink>
                    </TopbarNavItem>
                    <Dropdown 
                        animate={dropdown ? "open" : "closed"}
                        variants={variants}
                    >
                        <TextLink href="https://theeggandus.com/locations/issaquah" onClick={handleNav}>Issaquah</TextLink>
                        <TextLink href="https://theeggandus.com/locations/ballard" onClick={handleNav}>Ballard</TextLink>
                        <TextLink href="https://peteseggnest.com">Pete's Eggnest</TextLink>
                        <TextLink href="https://12thavenuecafe.com">12th Avenue Cafe</TextLink>
                        <TextLink href="https://chanterellewa.com">Chanterelle</TextLink>
                    </Dropdown>
                </DropdownLink>
                <TopbarNavItem onClick={handleNav}>
                    <TextLink href="https://theeggandus.com/contact-us">
                        <EggIcon alt="Egg Icon" /> Contact
                    </TextLink>
                </TopbarNavItem>
            </TopbarNav>
            <TopbarMobileNav>
                { nav ? <CloseIcon onClick={handleNav} /> : <MenuIcon  onClick={handleNav} /> }
            </TopbarMobileNav>
        </Topbar>
    );
}