import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

import { GlobalStyles } from './components/GlobalStyles';
import Navbar from './components/features/Navbar';
import CustomFooter from './components/features/CustomFooter'; 
import Gallery from './components/features/Gallery';

import { Alert, AlertGroup } from './components/shared/Alert';
import Button from './components/shared/Button'; 
import { Container } from './components/layout/Container';
import { Grid } from './components/layout/Grid';
import { TextLink } from './components/shared/TextLink';
import { Wrapper } from './components/layout/Wrapper';

import { ReactComponent as CheckmarkIcon } from './assets/icons/checkmark-icon.svg';
import { ReactComponent as Waves } from './assets/img/waves.svg';

const NoMatch = () => {
  let location = useLocation();

  return (
    <Redirect to="/" />
  );
}

export default function App() {
  const address = '7717 Greenwood Ave N, Seattle, WA 98103';
  const addressURL = 'http://www.google.com/maps/search/7717%20Greenwood%20Ave%20N%2C%20Seattle%2C%20WA%2098103';
  const phone = '206-784-5348';
  // const photos = [];
  
  const controls = useAnimation();
  const [ref, inView] = useInView();
  const variants = {
    visible: i => ({
      opacity: 1,
      transition: {
        delay: i * 1
      }
    }),
    hidden: { opacity: 0 }
  }

  useEffect(() => {
    if (inView) controls.start("visible");
  }, [controls, inView]);

  return (
    <>
      <GlobalStyles />
      <Navbar />
      <Wrapper>
        <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/v1608339692/the-egg-and-us/petes-eggnest-storefront.png" alt="Pete's Eggnest Storefront" />
        <Container
          ref={ref}
          animate={controls}
          initial="hidden"
          variants={variants}
        >
          <h1>Pete's Eggnest</h1>
          <Grid cols={2} gap={0}>
            <div>
              <h2>Location</h2>
              <TextLink href={addressURL} target="_blank" rel="noopener noreferrer">{address}</TextLink>
            </div>
            <div>
              <h2>Phone</h2>
              <TextLink href={`tel:${phone}`} data-bb-track-label="Location" data-bb-track="button" data-bb-track-on="click" data-bb-track-action="Click" data-bb-track-category="Phone Number">{phone}</TextLink>
            </div>
            <div>
              <h2>Hours</h2>
              <p>Monday - Friday: 7:00 AM - 2:00 PM</p>
              <p>Saturday & Sunday: 7:00 AM - 3:00 PM</p>
            </div>
            <div>
              <h2>Menu</h2>
              <TextLink href="https://drive.google.com/file/d/1VVkCGBLRpAWLwT1aa2h86EPtChE6ARdf/view?usp=sharing" target="_blank" rel="noreferrer noopener">Pete's Eggnest Menu</TextLink>
            </div>
              
          </Grid>
          <h2>Offering</h2>
            <Grid cols={2} gap={1}>
              <Alert status="success" color="dark">
                  <CheckmarkIcon /> Indoor Dining
              </Alert>
              <Alert status="success" color="dark">
                  <CheckmarkIcon /> Outdoor Dining
              </Alert>
              <Alert status="success" color="dark">
                  <CheckmarkIcon /> To-go
              </Alert>
              <Alert status="success" color="dark">
                  <CheckmarkIcon /> Delivery
              </Alert>
            </Grid>
          <h2>Online Ordering</h2>
          <Grid gap={1} cols={2}>
            <Button as="a" bg="ubereats" color="dark" size="md" href="https://www.ubereats.com/seattle/food-delivery/petes-egg-nest/PoSpC_APTeSbTPRgAz_shg" target="_blank" rel="noreferrer noopener">Order With Uber Eats</Button>
            <Button as="a" bg="grubhub" color="light" size="md" href="https://www.grubhub.com/restaurant/petes-egg-nest-7717-greenwood-ave-n-seattle/749326" target="_blank" rel="noreferrer noopener">Order With Grubhub</Button>
            <Button as="a" bg="postmates" color="light" size="md" href="https://postmates.com/merchant/petes-eggnest-seattle" target="_blank" rel="noreferrer noopener">Order With Postmates</Button>
            <Button as="a" bg="doordash" color="light" size="md" href="https://www.doordash.com/store/pete-s-egg-nest-seattle-37114/en-US" target="_blank" rel="noreferrer noopener">Order With Doordash</Button>
          </Grid>
          {/* <h2>Gallery</h2>
          <Gallery photos={photos} /> */}
        </Container>
      </Wrapper>
      <Waves style={{ display: `block` }} />
      <CustomFooter />
    </>
  );
}